import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//新增司机认证
export const saveDriverInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDriverInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 引用平台资料
export const SaveDriverInfoList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/SaveDriverInfoList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传身份证正面
export const uploadIdFace = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/IdFace`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传身份证反面
export const uploadIdBack = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/IdBack`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传驾驶证正面
export const uploadDriverFace = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DriverFace`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传驾驶证反面
export const uploadDriverBack = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DriverBack`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传道路运输许可证
export const uploadConveyLicence = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/ConveyLicence`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传司机照片
export const uploaDriverPhoto = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DriverPhoto`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//上传手持身份证
export const uploadHandIdCard = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/HandIdCard`, params,{cache: false}).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看司机详细信息
export const DriverDeatils = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Driver/DriverBaseComfir?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 修改司机信息
export const UpdateDriverInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/UpdateDriverInfo`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 通过身份证查询司机
export const TCDriverInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Driver/TCDriverInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 司机绑定车牌
export const saveDriverVehicleInfo = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Driver/SaveDriverVehicleInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

// 司机查询车牌
export const driverVehicleRelation = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Driver/DriverVehicleRelation`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 删除司机的车牌
export const deleteDriversCar = (params) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${host}/api/Driver/DeteDriversCarInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询运力池车辆基本信息
export const getVehicleInfo = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Applet/Car/TCBaseInfo?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查询车辆是否在企业名下
export const checkCarNumberByCompany = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Driver/CheckCarNumberByCompany?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//实名校验
export const realname = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/Driver/Realname`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 查看司机详细信息
export const inviteDriver = (params = {}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/v2/Driver/AppAppletQrCodeAsync?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject()
            }
        })
    })
}

