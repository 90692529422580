<template>
  <div class="pdfWrap" v-loading="flag.loadVehicleInfo">
    <div style="margin-bottom: 10px">
      <el-checkbox v-model="isMark">标记该司机为“已临时税务登记”</el-checkbox>
      <!-- <span style="font-size: 14px;"></span> -->
    </div>

    <div>
      <el-select
        v-model="currentCard"
        placeholder="请选择证件"
        style="margin-right: 8px"
      >
        <el-option label="全部" :value="6"></el-option>
        <el-option label="1证" :value="1"></el-option>
        <el-option label="2证" :value="2"></el-option>
        <el-option label="3证" :value="3"></el-option>
        <el-option label="4证" :value="4"></el-option>
        <el-option label="5证" :value="5"></el-option>
      </el-select>
      <el-select
        v-model="currentVehicleCarNumber"
        placeholder="请选择车辆"
        style="margin-right: 8px"
      >
        <el-option
          v-for="item in driverVehicleList"
          :key="item.VehicleCarNumber"
          :label="item.VehicleCarNumber"
          :value="item.VehicleCarNumber"
        >
        </el-option>
      </el-select>
      <el-button
        v-print="printObj"
        type="primary"
        size="small"
        icon="el-icon-printer"
        >打印</el-button
      >
      <el-button
        type="primary"
        size="small"
        :icon="downloadIcon"
        @click="downloadPDF()"
        >下载</el-button
      >
    </div>
    <div id="printMe" v-if="vehiclelist.length != 0">
      <el-divider content-position="center">
        <el-tag type="info">基本信息</el-tag>
      </el-divider>
      <el-table
        :data="vehiclelist"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
      >
        <el-table-column
          align="center"
          prop="DriverName"
          label="人员姓名"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="DriverIDCard"
          label="人员身份证号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="TelPhone"
          label="人员手机号码"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="VehicleCarNumber"
          label="车牌号"
        ></el-table-column>
      </el-table>
      <el-divider content-position="center">
        <el-tag type="danger">证件信息</el-tag>
      </el-divider>
      <!-- <div>
        <div class="content">
          <div class="upBox">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].DriverCardOnURL"
                :preview-src-list="[vehiclelist[0].DriverCardOnURL]" fit="cover"></el-image>
            </div>
            <div class="upText">身份证(正面)</div>
          </div>
          <div class="upBox">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].DriverCardBackURL"
                :preview-src-list="[vehiclelist[0].DriverCardBackURL]" fit="cover"></el-image>
            </div>
            <div class="upText">身份证(反面)</div>
          </div>
        </div>
        <div class="content" v-if="currentCard > 1">
          <div class="upBox" v-if="vehiclelist[0].DriverLicenseOnURL">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].DriverLicenseOnURL"
                :preview-src-list="[vehiclelist[0].DriverLicenseOnURL]" fit="cover"></el-image>
            </div>
            <div class="upText">驾驶证(正页)</div>
          </div>
          <div class="upBox" v-if="vehiclelist[0].DriverLicenseBackURL">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].DriverLicenseBackURL"
                :preview-src-list="[vehiclelist[0].DriverLicenseBackURL]" fit="cover"></el-image>
            </div>
            <div class="upText">驾驶证(反面)</div>
          </div>
        </div>
        <div class="content" v-if="currentCard > 2">
          <div class="upBox" v-if="vehiclelist[0].VehicleLicenseFrontPageURL">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].VehicleLicenseFrontPageURL"
                :preview-src-list="[vehiclelist[0].VehicleLicenseFrontPageURL]" fit="cover"></el-image>
            </div>
            <div class="upText">行驶证(正页)</div>
          </div>
          <div class="upBox" v-if="vehiclelist[0].VehicleLicenseSubPageOnURL">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].VehicleLicenseSubPageOnURL"
                :preview-src-list="[vehiclelist[0].VehicleLicenseSubPageOnURL]" fit="cover"></el-image>
            </div>
            <div class="upText">行驶证(反面)</div>
          </div>
        </div>
        <div v-if="vehiclelist[0].VehicleLicenseFrontPageURL && currentCard > 3">
          <el-table style="margin:40px 0 20px 0;" :data="vehiclelist"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-if="vehiclelist[0].RTQCertificateURL || vehiclelist[0].VehicleRTPUTL">
            <el-table-column align="center" prop="DriverName" label="人员姓名"></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="人员身份证号"></el-table-column>
            <el-table-column align="center" prop="TelPhone" label="人员手机号码"></el-table-column>
            <el-table-column align="center" prop="VehicleCarNumber" label="车牌号"></el-table-column>
          </el-table>
        </div>
        <div class="content">
          <div class="upBox" v-if="vehiclelist[0].VehicleRTPUTL && currentCard > 3">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].VehicleRTPUTL"
                :preview-src-list="[vehiclelist[0].VehicleRTPUTL]" fit="cover">
              </el-image>
            </div>
            <div class="upText">道路运输证</div>
          </div>
          <div class="upBox" v-if="vehiclelist[0].RTQCertificateURL && currentCard > 4">
            <div class="img-box">
              <el-image class="img" :src="vehiclelist[0].RTQCertificateURL"
                :preview-src-list="[vehiclelist[0].RTQCertificateURL]" fit="cover"></el-image>
            </div>
            <div class="upText">从业资格证</div>
          </div>

        </div>
      </div> -->
      <div style="column-count: 2; margin: 0 20rpx">
        <div class="upBox">
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].DriverCardOnURL"
              :preview-src-list="[vehiclelist[0].DriverCardOnURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">身份证(正面)</div>
        </div>
        <div class="upBox">
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].DriverCardBackURL"
              :preview-src-list="[vehiclelist[0].DriverCardBackURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">身份证(反面)</div>
        </div>
        <div
          class="upBox"
          v-if="vehiclelist[0].DriverLicenseOnURL && currentCard > 1"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].DriverLicenseOnURL"
              :preview-src-list="[vehiclelist[0].DriverLicenseOnURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">驾驶证(正页)</div>
        </div>
        <div
          class="upBox"
          v-if="vehiclelist[0].DriverLicenseBackURL && currentCard > 1"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].DriverLicenseBackURL"
              :preview-src-list="[vehiclelist[0].DriverLicenseBackURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">驾驶证(反面)</div>
        </div>
        <div
          class="upBox"
          v-if="vehiclelist[0].VehicleLicenseFrontPageURL && currentCard > 2"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].VehicleLicenseFrontPageURL"
              :preview-src-list="[vehiclelist[0].VehicleLicenseFrontPageURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">行驶证(正页)</div>
        </div>
        <div
          class="upBox"
          v-if="vehiclelist[0].VehicleLicenseSubPageOnURL && currentCard > 2"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].VehicleLicenseSubPageOnURL"
              :preview-src-list="[vehiclelist[0].VehicleLicenseSubPageOnURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">行驶证(反面)</div>
        </div>
        <!-- <div v-if="vehiclelist[0].VehicleLicenseFrontPageURL && currentCard > 3">
          <el-table style="margin:40px 0 20px 0;" :data="vehiclelist"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            v-if="vehiclelist[0].RTQCertificateURL || vehiclelist[0].VehicleRTPUTL">
            <el-table-column align="center" prop="DriverName" label="人员姓名"></el-table-column>
            <el-table-column align="center" prop="DriverIDCard" label="人员身份证号"></el-table-column>
            <el-table-column align="center" prop="TelPhone" label="人员手机号码"></el-table-column>
            <el-table-column align="center" prop="VehicleCarNumber" label="车牌号"></el-table-column>
          </el-table>
        </div> -->
        <div
          class="upBox"
          v-if="vehiclelist[0].VehicleRTPUTL && currentCard > 3"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].VehicleRTPUTL"
              :preview-src-list="[vehiclelist[0].VehicleRTPUTL]"
              fit="cover"
            >
            </el-image>
          </div>
          <div class="upText">道路运输证</div>
        </div>
        <div
          class="upBox"
          v-if="vehiclelist[0].RTQCertificateURL && currentCard > 4"
        >
          <div class="img-box">
            <el-image
              class="img"
              :src="vehiclelist[0].RTQCertificateURL"
              :preview-src-list="[vehiclelist[0].RTQCertificateURL]"
              fit="cover"
            ></el-image>
          </div>
          <div class="upText">从业资格证</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tCBaseInfo } from "@/api/transport/vehicleManage/index";
import { UpdateTaxRegister } from "@/api/auditInfo/certified/index";
import { _getBase64 } from "@/utils/utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default {
  props: {
    _vehiclelist: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _driverVehicleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isMark: true,
      flag: {
        loadVehicleInfo: false, //获取车辆信息
      },
      downloadIcon: "el-icon-bottom",
      printObj: {
        id: "printMe",
        popTitle: "",
        // modalMessage: '',
        clickMounted: () => {
          this.UpdateTaxRegister();
        },
      },
      vehiclelist: [], //车辆信息
      driverVehicleList: [], //司机关联的车辆
      currentVehicleCarNumber: "", //当前选择的车辆车牌
      currentCard: 6, //选择的证件展示
    };
  },
  methods: {
    UpdateTaxRegister() {
      if (this.isMark) {
        let data = {
          User_IDS: [],
          TaxRegisterStatus: "2", // 税务临时登记
          TaxRegisterRemark: "",
        };
        this.vehiclelist.forEach((item) => {
          let params = {
            UserID: item.UserID,
            AscriptionUserID: item.CompanyUserID,
          };
          data.User_IDS.push(params);
        });
        UpdateTaxRegister(data).then((res) => {
          this.$emit("isClickPrint", true);
        });
      }
    },
    // 下载
    downloadPDF() {
      this.downloadIcon = "el-icon-loading";
      html2canvas(document.getElementById("printMe"))
        .then((canvas) => {
          var contentWidth = canvas.width;
          var contentHeight = canvas.height;
          //一页pdf显示html页面生成的canvas高度
          var pageHeight = (contentWidth / 592.28) * 841.89;
          //未生成pdf的html页面高度
          var leftHeight = contentHeight;
          //页面偏移
          var position = 0;
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          var imgWidth = 595.28;
          var imgHeight = (592.28 / contentWidth) * contentHeight;
          var pageData = canvas.toDataURL("image/jpeg", 1.0);
          var pdf = new jsPDF("", "pt", "a4");
          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          if (leftHeight < pageHeight) {
            pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          } else {
            while (leftHeight > 0) {
              pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
              leftHeight -= pageHeight;
              position -= 841.89;
              //避免添加空白页
              if (leftHeight > 0) {
                pdf.addPage();
              }
            }
          }
          //设置文件名
          pdf.save(
            `${this.vehiclelist[0].DriverName}-${this.vehiclelist[0].TelPhone}.pdf`
          );
        })
        .finally(() => {
          this.downloadIcon = "el-icon-bottom";
        });
    },
  },
  created() {
    this.vehiclelist = this._vehiclelist;
    this.currentVehicleCarNumber = this._vehiclelist[0].VehicleCarNumber;
    let infoObj = this.vehiclelist.length ? this.vehiclelist[0] : {};
    this.printObj.popTitle = `姓名：${infoObj.DriverName}  身份证号：${infoObj.DriverIDCard} 手机号码：${infoObj.TelPhone} 车牌号：${infoObj.VehicleCarNumber}`;
  },
  watch: {
    _driverVehicleList(val) {
      this.driverVehicleList = val;
    },
    //下拉框变化获取车辆信息
    currentVehicleCarNumber(val) {
      this.flag.loadVehicleInfo = true;
      tCBaseInfo({ carNumber: val })
        .then((res) => {
          if (res.data.ConfirmInfo == null) return;
          this.vehiclelist[0].VehicleLicenseFrontPageURL = "";
          this.vehiclelist[0].VehicleLicenseSubPageOnURL = "";
          this.vehiclelist[0].VehicleRTPUTL = "";
          _getBase64(res.data.ConfirmInfo.VehicleLicenseFrontPageURL).then(
            (VehicleLicenseFrontPageURL) => {
              this.vehiclelist[0].VehicleLicenseFrontPageURL =
                VehicleLicenseFrontPageURL;
            }
          );
          _getBase64(res.data.ConfirmInfo.VehicleLicenseSubPageOnURL).then(
            (VehicleLicenseSubPageOnURL) => {
              this.vehiclelist[0].VehicleLicenseSubPageOnURL =
                VehicleLicenseSubPageOnURL;
            }
          );
          _getBase64(res.data.ConfirmInfo.VehicleRTPUTL).then(
            (VehicleRTPUTL) => {
              this.vehiclelist[0].VehicleRTPUTL = VehicleRTPUTL;
            }
          );
          this.vehiclelist[0].VehicleCarNumber = val;
          let infoObj = this.vehiclelist.length ? this.vehiclelist[0] : {};
          this.printObj.popTitle = `姓名：${infoObj.DriverName}  身份证号：${infoObj.DriverIDCard} 手机号码：${infoObj.TelPhone} 车牌号：${val}`;
        })
        .finally(() => {
          this.flag.loadVehicleInfo = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// .content {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   .upBox {
//     text-align: center;
//     width: 350px;
//     height: 430px;
//     margin: 10px 0px;
//     overflow-y: hidden;

//     .img-box {
//       height: 400px;
//       overflow-y: hidden;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }

//     .upText {
//       font-size: 14px;
//       font-weight: bold;
//       margin: 10px 0;
//       color: #333333;
//     }

//     .img {
//       border-radius: 10px;
//     }
//   }
// }
.upBox {
  margin-bottom: 15px;
  break-inside: avoid;
}

.img-box {
  width: 100%;

  .img {
    width: 100%;
  }
}

.upText {
  text-align: center;
}
</style>
