import axios from "@/api/config/interceptor";
import { host } from "@/api/config/host";
import { _paramsToQueryString } from "@/utils/utils";

// 认证货主&服务商
// 查询列表（货主，服务商）列表
export const GetEnterprise = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseByCustomerList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//修改货主备注
export  const UpdateVerifyRemark= (params) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ConsInfo/UpdateVerifyRemark`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

// 认证司机
// 查询司机列表
export const GetPlatFormBy = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetPlatFormByDriverList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 查看司机详情
export const DriverDeatils = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Driver/DriverBaseComfir?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 认证司机
export const CertificationDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatForm/CertificationDriver?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 认证车辆
// 查询车辆列表
export const VehicleList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetPlatFormByVehicleList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取车辆信息
export const VehicleDeatils = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/Vehicle/VehicleDeatils?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 认证车辆
export const CertificationVehicle = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatForm/CertificationVehicle?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 设置服务商分成比例的列表
export const BySupplierList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseBySupplierList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 设置服务商分成比例
export const UpdateCommission = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/Commission/UpdateCommission`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询所有服务商
export const SupplierAllList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseBySupplierAllList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 查询所有货主
export const CustomerAllList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseByCustomerAllList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 服务商所有司机/所有车辆查看运单统计
export const GetWayBillAll = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${host}/api/WayBill/GetWayBillAll?${_paramsToQueryString(params)}`)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 查询平台所有车辆
export const allVehicle = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetPlatFormByVehicleAllList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};
// 查询平台所有司机
export const allDriverList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetPlatFormByDriverAllList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询服务商发票类型
export const getPlatFormBy = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatForm/GetPlatFormByInvoiceTypeList?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 查询平台所有代理商
export const getAllAgency = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseByAgentAllList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//
//
//                  分割线  代理商接口
//
//

// 获取代理商列表
export const ByAgentList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseByAgentList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 获取代理商的发票类型
export const GetPlatFormByAgentInvoice = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatForm/GetPlatFormByAgentInvoiceTypeList?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

//
export const getAgentList = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetEnterpriseByAgentList`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 通过ID 查询人员
export const GetTCDriverVehicle = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/PlatForm/GetTCDriverVehicle?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 修改司机税务登记
export const UpdateTaxRegister = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/PlatForm/UpdateTaxRegister`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 根据身份证查询挂靠关系
export const GetTCDriverRelation = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetTCDriverRelation`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 根据车牌号查询挂靠关系
export const GetTCVehicleRelation = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/PlatForm/GetTCVehicleRelation`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 删除运力池司机
export const deleteTCDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/Driver/DeleteTCDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 删除企业司机
export const deleteDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/Driver/DeleteDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 查询车辆最新位置
export const GetCarNewLocation = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/Vehicle/GetCarNewLocation?${_paramsToQueryString(params)}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 司机自动审核 - 查询相关企业
export const enterpriseDriverNum = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${host}/api/v2/AutoVerify/QueryEnterprise`, params)
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 司机自动审核 - 提交相关企业审核
export const putEnterpriseDriverAuto = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/SubmitDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 司机自动审核 --批量审核错误司机
export const BatchFail = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/BatchFail`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 车辆自动审核 - 提交相关企业车辆
export const putEnterpriseCarAuto = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/SubmitCar`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 车辆自动审核 --批量审核错误车辆
export const BatchFailCar = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/BatchFailCar`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 通过ID 查询人员
export const GetVerifyPower = (params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${host}/api/v2/AutoVerify/GetVerifyPower?${_paramsToQueryString(
          params
        )}`
      )
      .then((res) => {
        if (res.code == 0) {
          resolve(res.data);
        } else {
          reject(res);
        }
      });
  });
};

// 批量提交到草稿--司机
export const BatchDraftDriver = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/BatchDraftDriver`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

// 批量提交到草稿--车辆
export const BatchDraftCar = (params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${host}/api/v2/AutoVerify/BatchDraftCar`, params).then((res) => {
      if (res.code == 0) {
        resolve(res.data);
      } else {
        reject(res);
      }
    });
  });
};

